import React from "react"
import PortableText from "@sanity/block-content-to-react"

// Serializer Components
import Figure from "./article/Figure"
import Video from "./article/Video"
import BlockQuote from "./article/BlockQuote"
import PullQuote from "./article/PullQuote"

export default function PortableTextRenderer({ text }) {
  const serializers = {
    marks: {
      internalLink: ({ mark, children }) => {
        const { type, slug = {}, url } = mark
        const href = url ? url : `/${type}/${slug.current}`
        return <a href={href}>{children}</a>
      },

      externalLink: ({ mark, children }) => {
        const { blank, href } = mark
        return blank ? (
          <a href={href} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        ) : (
          <a href={href} rel="noopener noreferrer">
            {children}
          </a>
        )
      },
    },
    types: {
      centeredText: ({ node }) => {
        const { text } = node

        return (
          <div style={{ textAlign: `center` }}>
            <PortableText blocks={text} />
          </div>
        )
      },

      figure: Figure,

      video: Video,

      blockQuote: ({ node }) => {
        const { text, source } = node

        return (
          <BlockQuote source={source}>
            <PortableText blocks={text} />
          </BlockQuote>
        )
      },

      pullQuote: ({ node }) => {
        const { text, source } = node

        return (
          <PullQuote source={source}>
            <PortableText blocks={text} />
          </PullQuote>
        )
      },
    },
  }

  return (
    <>
      <PortableText blocks={text} serializers={serializers} />
    </>
  )
}
