import React from "react"
import styled from "styled-components"
import CornerDownRight from "../svg/CornerDownRight"

export default function PullQuote({ children, source }) {
  return (
    <Wrapper>
      {children}
      {source && (
        <Citation>
          <CornerDownRight />
          {source}
        </Citation>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.blockquote`
  --border-width: 5px;
  margin: 3rem 0;
  padding: 1rem 1.5rem;
  background-color: rgba(var(--maroon-rgb), 0.065);
  border-radius: var(--border-width);
  text-align: left;
  color: var(--page-color);
  transition: all 0.15s ease-in-out;

  p {
    margin: 0;
    font-size: 1.05em;
    line-height: 1.35;
  }

  @media only screen and (min-width: 767px) {
    max-width: 12.5em;

    &:nth-of-type(odd) {
      float: right;
      margin: 0.5rem -1.5rem 0.5rem 1.5rem;
      border-right: var(--border-width) solid var(--maroon);
    }

    &:nth-of-type(even) {
      float: left;
      margin: 0.5rem 1.5rem 0.5rem -1.5rem;
      border-left: var(--border-width) solid var(--maroon);
    }
  }
`

const Citation = styled.cite`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  font-size: 0.85em;
  font-style: normal;
  color: var(--page-color);

  svg {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    width: 16px;
    height: 16px;
    padding-bottom: 0.25em;
  }
`
