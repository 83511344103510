import React from "react"
import { getGatsbyImageData } from "gatsby-source-sanity"
import { GatsbyImage } from "gatsby-plugin-image"
import ImageZoom from "../ImageZoom"
import styled from "styled-components"
import clientConfig from "../../../client-config"

export default function Figure({ node }) {
  if (!node.image.asset) {
    return null
  }

  if (node.image.asset.mimeType === "image/gif") {
    return (
      <FigureWrapper>
        <ImageZoom>
          <img
            src={node.image.asset.url}
            alt={node.alt}
            className="imageWrapper"
          />
        </ImageZoom>
        {node.caption && <figcaption>{node.caption}</figcaption>}
      </FigureWrapper>
    )
  }

  const gatsbyImageData = getGatsbyImageData(
    node.image.asset,
    { maxWidth: 700 },
    clientConfig.sanity
  )

  return (
    <FigureWrapper>
      <ImageZoom>
        <GatsbyImage
          image={gatsbyImageData}
          alt={node.alt}
          className="imageWrapper"
        />
      </ImageZoom>
      {node.caption && <figcaption>{node.caption}</figcaption>}
    </FigureWrapper>
  )
}

const FigureWrapper = styled.figure`
  margin: 3rem auto;
  text-align: center;
  display: block;
  width: 100%;
  overflow: hidden;

  .imageWrapper {
    border-radius: 5px;
    overflow: hidden;
    backface-visibility: hidden;
    transform: translateZ(0) translate3d(0, 0, 0);
    mask-image: -webkit-radial-gradient(white, black);

    img {
      border-radius: 5px;
      will-change: transform;
      transition: 0.85s transform ease-out;
    }
  }

  &:hover .imageWrapper {
    img {
      transform: scale(1.1);
      transition: 0.2s transform ease;
    }
  }

  figcaption {
    margin-top: 0.75em;
    font-size: 0.8em;
    text-align: center;
    font-style: italic;
    color: var(--gray);
  }
`
