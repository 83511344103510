import React from "react"
import styled from "styled-components"
import CornerDownRight from "../svg/CornerDownRight"

export default function BlockQuote({ children, source }) {
  return (
    <Wrapper>
      {children}
      {source && (
        <Citation>
          <CornerDownRight />
          {source}
        </Citation>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.blockquote`
  margin: 1rem -1.5rem 1rem -1.5rem;
  padding: 1.25rem 1.5rem;
  background-color: rgba(var(--maroon-rgb), 0.065);
  text-align: left;
  color: var(--page-color);

  p {
    margin: 0;
    line-height: 1.55;
  }

  @media only screen and (min-width: 651px) {
    border-radius: 5px;
  }
`

const Citation = styled.cite`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  font-size: 0.85em;
  font-style: normal;
  color: var(--page-color);

  svg {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    width: 16px;
    height: 16px;
    padding-bottom: 0.25em;
  }
`
