import React from "react"
import styled from "styled-components"

export default function Video({ node }) {
  if (!node.url) {
    return null
  }

  return (
    <FigureWrapper>
      <iframe
        src={node.url}
        title={node.caption || "Video Preview"}
        width="640"
        height="360"
        className="videoWrapper"
        frameBorder="0"
      ></iframe>

      {node.caption && <figcaption>{node.caption}</figcaption>}
    </FigureWrapper>
  )
}

const FigureWrapper = styled.figure`
  margin: 3rem -1.5rem;
  text-align: center;
  display: block;
  overflow: hidden;

  .videoWrapper {
    overflow: hidden;
    backface-visibility: hidden;
    transform: translateZ(0) translate3d(0, 0, 0);
    mask-image: -webkit-radial-gradient(white, black);

    @media only screen and (min-width: 651px) {
      border-radius: 5px;
    }
  }

  iframe {
    aspect-ratio: 640 / 360;
    width: 100%;
    height: auto;
  }

  figcaption {
    margin-top: 0.75em;
    font-size: 0.8em;
    text-align: center;
    font-style: italic;
    color: var(--gray);
  }
`
